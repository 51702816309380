import React from 'react';

import { WagmiProvider, createConfig, Config } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ConnectKitProvider, getDefaultConfig } from 'connectkit';
import { eon } from 'wagmi/chains';

// Define the type for the wallet object
interface Wallet {
  id: string;
  name: string;
  // Add other properties as needed
}

const config: Config = createConfig(
  getDefaultConfig({
    appName: 'ZenFoxes',
    walletConnectProjectId: "802017240c9fd0bb3a812cda44583804",
    chains: [eon],
    ssr: false,
    // Exclude Coinbase wallet
    wallets: (defaultWallets: Wallet[]) => 
      defaultWallets.filter((wallet: Wallet) => wallet.id !== 'coinbase'),
  } as any & { wallets: (wallets: Wallet[]) => Wallet[] })
);

const queryClient = new QueryClient();

interface Web3ProviderProps {
  children: React.ReactNode;
}

export const Web3Provider: React.FC<Web3ProviderProps> = ({ children }) => {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>{children}</ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};