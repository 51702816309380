import { api } from "convex/_generated/api";
import { useMutation } from "convex/react";
import { TelegramInitData, User, InitMiniAppResponse } from '@/types/Types';
import { useState } from "react";

export const useInitMiniApp = () => {
  const mutate = useMutation(api.miniApp.initMiniApp);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const execute = async (initData: TelegramInitData): Promise<InitMiniAppResponse> => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await mutate(initData);
      // console.log("useInitMiniApp response", response);
      return response;
    } catch (err: any) {
      console.error("useInitMiniApp error:", err);
      setError(err);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { execute, isLoading, error };
};

export const useGetMe = () => {
  const mutation = useMutation(api.miniApp.getMe);

  return async (token: string): Promise<{ user: User }> => {
    const response = await mutation({ token });
    // console.log("useGetMe response", response);
    return response;
  };
};
