import { useState, useEffect } from 'react'
import { useLaunchParams } from '@telegram-apps/sdk-react'
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { Card, CardContent } from "@/components/ui/card"
import { Battery, Gem, Plus, HelpCircle } from 'lucide-react'
import { ConnectKitButton } from 'connectkit';

interface ProfilePhoto {
  file_id: string;
  file_unique_id: string;
  width: number;
  height: number;
  file_size: number;
}

interface TelegramProfilePhotos {
  total_count: number;
  photos: ProfilePhoto[][];
}

export default function GameHeader() {
  const { initData } = useLaunchParams()
  const [profilePicture, setProfilePicture] = useState<string | null>(null)

  const userName = initData?.user?.firstName || 'Anonymous'
  const userId = initData?.user?.id

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (!userId) return

      try {
        const profilePhotos = await getProfilePhotos(userId.toString())
        if (profilePhotos && profilePhotos.photos.length > 0) {
          const fileId = profilePhotos.photos[0][0].file_id
          const photoUrl = await getPhotoUrl(fileId)
          setProfilePicture(photoUrl)
        }
      } catch (error) {
        console.error('Error fetching profile picture:', error)
      }
    }

    fetchProfilePicture()
  }, [userId])

  const getProfilePhotos = async (userId: string): Promise<TelegramProfilePhotos | null> => {
    const response = await fetch(`https://api.telegram.org/bot${import.meta.env.VITE_TELEGRAM_BOT_TOKEN}/getUserProfilePhotos?user_id=${userId}`)
    if (!response.ok) return null
    const data = await response.json()
    return data.result
  }

  const getPhotoUrl = async (fileId: string): Promise<string | null> => {
    const response = await fetch(`https://api.telegram.org/bot${import.meta.env.VITE_TELEGRAM_BOT_TOKEN}/getFile?file_id=${fileId}`)
    if (!response.ok) return null
    const data = await response.json()
    return `https://api.telegram.org/file/bot${import.meta.env.VITE_TELEGRAM_BOT_TOKEN}/${data.result.file_path}`
  }

  return (
    <Card className="w-full bg-transparent text-white">
      <CardContent className="p-2">
        <div className="flex items-center justify-between rounded-lg ml-2">
          {/* left side */}
          <div className="flex items-center space-x-2 bg-transparent rounded-xl p-1 pl-0">
            {/* Avatar component with fallback */}
            <Avatar className="w-8 h-8 border-2 border-emerald-500">
              {profilePicture ? (
                <AvatarImage src={profilePicture} alt={userName} />
              ) : (
                <AvatarFallback>{userName.slice(0, 2).toUpperCase()}</AvatarFallback>
              )}
            </Avatar>
         <div className="flex flex-row items-center">
              <span className="text-sm font-semibold truncate max-w-[150px]">{userName}</span>
              <div className="flex items-end space-x-2 flex-col pr-1">
                <div className="flex items-center">
                  <Battery className="w-2 h-2 text-blue-500 mr-1" />
                  <span className="text-xs text-blue-500">0</span>
                </div>
                <div className="flex items-center">
                  <Gem className="w-2 h-2 text-purple-500 mr-1" />
                  <span className="text-xs text-purple-500">0</span>
                </div>
              </div>
            </div> 
          </div>
          {/* right side */}
          <div className="flex items-center space-x-1 sm:space-x-2">
          <ConnectKitButton theme='midnight' showAvatar={false} showBalance={false} mode='dark' customTheme={{
            '--ck-connectbutton-border-radius': '0.5rem',
            '--ck-connectbutton-color': '#fff',
            '--ck-connectbutton-border': '1px solid #fff',
            '--ck-connectbutton-font-size': '1rem',
            '--ck-connectbutton-font-weight': '600',
            '--ck-connectbutton-padding': '0.5rem 1rem',
            '--ck-connectbutton-margin': '0',
          }}  />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}