import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';

interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('LoadingContext: isLoading changed:', isLoading);
  }, [isLoading]);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (context === undefined) {
    console.error('useLoading must be used within a LoadingProvider');
    return { isLoading: false, setIsLoading: () => {} };
  }
  return context;
};