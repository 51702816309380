import React, { useEffect, useCallback } from 'react';
import {
	useMainButton,
	useMiniApp,
	useHapticFeedback,
	useLaunchParams,
} from '@telegram-apps/sdk-react';
import { useLanguage } from '@/utils/LanguageContext';
import { Gamepad2, Trophy, Coins } from 'lucide-react';
import SparklesText from "@/components/magicui/sparkles-text";

interface OnboardingProps {
	onComplete: () => void;
}

const Onboarding: React.FC<OnboardingProps> = ({ onComplete }) => {
	const { t } = useLanguage();
	const miniApp = useMiniApp();
	const launch = useLaunchParams();
	const mainButton = useMainButton();
	const hapticFeedback = useHapticFeedback();

	useEffect(() => {
		miniApp.ready();
	}, [miniApp]);

	const handleMainButtonClick = useCallback(() => {
		hapticFeedback.impactOccurred('medium');
		mainButton.showLoader();
		onComplete();
		mainButton.hideLoader();
	}, [hapticFeedback, onComplete, mainButton]);

	useEffect(() => {
		mainButton.setParams({
			text: t('common.getStarted'),
			isEnabled: true,
		});
		mainButton.show();

		mainButton.on('click', handleMainButtonClick);

		return () => {
			mainButton.off('click', handleMainButtonClick);
		};
	}, [mainButton, handleMainButtonClick, t]);

	return (
		<div className="min-h-screen flex flex-col items-center p-6 touch-pan-y select-none bg-[#c3c7d3] text-[#222222] bg-no-repeat" aria-label={t('onboarding.title')}
			style={{
				background: `linear-gradient(to bottom, hsla(220, 13%, 80%, 0.8) 0%, hsla(220, 13%, 80%, 0.95) 50%), url("/grad5a.jpg")`,
				backgroundSize: '100% 100%',
				backgroundPosition: 'bottom',
				backgroundRepeat: 'no-repeat',
				backgroundBlendMode: 'normal, overlay',
			}}
		>
			<div className="flex-1 w-full max-w-[600px] flex flex-col justify-center relative overflow-hidden touch-none">
				<div className="flex-none w-full flex flex-col items-center px-4 text-center">
					<div className="w-full h-60 flex justify-center items-center mb-8">
						<img src="/zf-logo.png" alt="ZF Logo" className="w-full h-full object-contain" />
					</div>
					<SparklesText text="Welcome" />
					<h1 className="text-3xl font-bold mb-2">
					{launch.initData?.user?.firstName
							? `${launch.initData.user.firstName}!`
							: '!'}
					</h1>
					<p className="text-lg mb-8 max-w-[320px]">
						Ready to play and earn? Join now!
					</p>
					<ul className="w-full max-w-[320px] space-y-4">
						<li className="flex items-center justify-center">
							<Gamepad2 className="mr-4 text-blue-500" size={24} />
							<span>Play exciting games</span>
						</li>
						<li className="flex items-center justify-center">
							<Trophy className="mr-4 text-yellow-500" size={24} />
							<span>Compete in tournaments</span>
						</li>
							<li className="flex items-center justify-center">
							<Coins className="mr-4 text-green-500" size={24} />
							<span>Earn tokens and NFTs</span>
						</li>
						</ul>
				</div>
			</div>
			<p className="text-xs text-center max-w-[250px] text-black/45">
				{t('onboarding.termsPrefix')} <br />
				<a href="https://telegram.org/tos/mini-apps" className="underline">{t('onboarding.termsLink')}</a>{' '}
				{t('common.and')} <a href="/privacy" className="underline">{t('onboarding.privacyLink')}</a>
			</p>
		</div>
	);
};

export default Onboarding;
