import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
	bindMiniAppCSSVars,
	bindThemeParamsCSSVars,
	bindViewportCSSVars,
	useLaunchParams,
	useMiniApp,
	useThemeParams,
	useViewport,
	useClosingBehavior,
	useBackButton,
	useSwipeBehavior,
	postEvent
} from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalProvider } from '@/context/GlobalContext';
import { LanguageProvider } from '@/utils/LanguageContext';
import InitializerPage from '@/layouts/InitializerPage/InitializerPage';
import Loading from '@/utils/Loading';
import ErrorFallback from '@/utils/ErrorFallback';
import { useLoading } from '@/context/LoadingContext';
import { ConvexProvider, ConvexReactClient } from "convex/react";

import '@telegram-apps/telegram-ui/dist/styles.css';

const convexUrl = "https://fine-armadillo-860.convex.cloud"; // TODO: make this dev variable
if (!convexUrl) {
	throw new Error("VITE_CONVEX_URL is not set in the environment variables");
}

const convex = new ConvexReactClient(convexUrl);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 60 * 1000,
			gcTime: 10 * 60 * 1000,
			refetchOnWindowFocus: false,
			},
		},
	});

export const App: React.FC = () => {
	const { isLoading, setIsLoading } = useLoading();
	const lp = useLaunchParams();
	const miniApp = useMiniApp();
	const themeParams = useThemeParams();
	const viewport = useViewport();
	const closingBehavior = useClosingBehavior();
	const backButton = useBackButton();
	const swipeBehavior = useSwipeBehavior();
	const [language, setLanguage] = useState('en');

	useEffect(() => {
		swipeBehavior.disableVerticalSwipe();
		postEvent('web_app_set_background_color', { color: '#111112' });
		postEvent('web_app_set_header_color', { color: '#111112' });
		setTimeout(() => {
			setIsLoading(false);
		}, 1500);
	}, [swipeBehavior, setIsLoading]);

	useEffect(() => {
		if (viewport) {
			bindViewportCSSVars(viewport);
			viewport.expand();
			swipeBehavior.disableVerticalSwipe();
		}
	}, [viewport, swipeBehavior]);

	useEffect(() => {
		bindMiniAppCSSVars(miniApp, themeParams);
	}, [miniApp, themeParams]);

	useEffect(() => {
		bindThemeParamsCSSVars(themeParams);
	}, [themeParams]);

	useEffect(() => {
		closingBehavior.enableConfirmation();
		return () => closingBehavior.disableConfirmation();
	}, [closingBehavior]);

	useEffect(() => {
		backButton.hide();
		return () => {
			backButton.hide();
		};
	}, [backButton]);


	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<ConvexProvider client={convex}>
				<AppRoot
					appearance={'dark'}
					platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
				>
					<QueryClientProvider client={queryClient}>
						<LanguageProvider languageCode={language}>
							<GlobalProvider initialLanguage={language} setLanguage={setLanguage}>
								{isLoading ? (
									<Loading />
								) : (
									<InitializerPage />
								)}
							</GlobalProvider>
						</LanguageProvider>
					</QueryClientProvider>
				</AppRoot>
			</ConvexProvider>
		</ErrorBoundary>
	);
};

export default App;
