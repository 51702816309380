import React from 'react';
// import { Spinner } from '@telegram-apps/telegram-ui';
import './Loader.css';

// remove spinner from the project including here

const Loading: React.FC = () => (
	<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
		<div className="pl">
			<div className="pl__dot pl__dot--a"></div>
			<div className="pl__dot pl__dot--b"></div>
			<div className="pl__dot pl__dot--c"></div>
			<div className="pl__dot pl__dot--d"></div>
		</div>
	</div>
);

export default Loading;
