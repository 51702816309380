import { Suspense, useState, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import { useLaunchParams } from '@telegram-apps/sdk-react';

export default function SplineScene() {
  const [shouldRenderSpline, setShouldRenderSpline] = useState(false);
  const lp = useLaunchParams();

  useEffect(() => {
    // Check if the platform can handle large Spline scenes
  const splineCompatiblePlatforms = ['tdesktop', 'macos', 'web'];
    if (splineCompatiblePlatforms.includes(lp.platform)) {
      setShouldRenderSpline(true);
    } else {
      setShouldRenderSpline(false);
    }
  }, [lp.platform]);
 
  if (!shouldRenderSpline) {
    return <img src="/xx.png?v=1" alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center', backgroundColor: 'black' }} />;
  }

  return (
    <Suspense fallback={<></>}>
      <Spline scene="/xx.splinecode" />
    </Suspense>
  );
}
