    import React from 'react';
    import { FallbackProps } from 'react-error-boundary';
    import { useLanguage } from '@/utils/LanguageContext';

    const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
    	const { t } = useLanguage();

    	const getErrorMessage = (error: Error) => {
    		try {
    			// First, try to translate the error message
    			const translatedMessage = t(error.message);
    			if (translatedMessage !== error.message) {
    				return translatedMessage;
    			}
    			// If translation failed, return the original error message
    			return error.message;
    		} catch (translationError) {
    			console.error('Translation error:', translationError);
    			// If there's an error in translation, return a fallback message
    			return 'An unexpected error occurred';
    		}
    	};

    	const getTitle = () => {
    		try {
    			return t('error.title');
    		} catch (translationError) {
    			console.error('Translation error for title:', translationError);
    			return 'Error';
    		}
    	};

    	const getRetryText = () => {
    		try {
    			return t('error.retry');
    		} catch (translationError) {
    			console.error('Translation error for retry text:', translationError);
    			return 'Retry';
    		}
    	};

    	return (
    		<div role="alert">
    			<h2>{getTitle()}</h2>
    			<pre>{getErrorMessage(error)}</pre>
    			<button onClick={() => window.location.reload()}>{getRetryText()}</button>
    		</div>
    	);
    };

    export default ErrorFallback;
