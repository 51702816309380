import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Card, CardContent } from '@/components/ui/card';
import SplineScene from './SplineScene';
import { PlugZap, PackagePlus, Zap, Share } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { initUtils } from '@telegram-apps/sdk-react';
import MessageBoard from './MessageBoard'; // Import the MessageBoard component
import { useMiniApp, useLaunchParams } from '@telegram-apps/sdk-react'

// Constants
const TAP_DELAY = 50;
const PLUS_TEN_DURATION = 1500;
const BOOST_INCREMENT = 3600;
const INITIAL_DAILY_INCOME = 140400;
const INITIAL_BOOST_TIME = 7200;
const INITIAL_CLAIM_TIME = 28477;
const RANDOM_POSITION_RANGE = 150;

export function CryptoGameUi() {
  // 🧮 State variables
  const [score, setBalance] = useState(1);
  const [energy, setEnergy] = useState(1);
  const [plusTens, setPlusTens] = useState<Array<{ id: number; x: number; y: number; rotation: number }>>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());
  const [dailyIncome, setDailyIncomeLimit] = useState(INITIAL_DAILY_INCOME);
  const [boostTime, setBoostTime] = useState(INITIAL_BOOST_TIME);
  const [claimTime, setClaimTime] = useState(INITIAL_CLAIM_TIME);
  const [unclaimedPoints, setUnclaimedPoints] = useState(0);
  const { initData } = useLaunchParams()

  const userName = initData?.user?.firstName || 'Anonymous'

  const utils = initUtils();

  // 🔢 Format number for display
  const formatNumber = (num: number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      compactDisplay: 'short',
      minimumFractionDigits: num < 100000 ? 0 : 1,
      maximumFractionDigits: num < 100000 ? 0 : 3,
    });
    return formatter.format(num);
  };

  // 👆 Handle tap event
  const handleTap = () => {
    const currentTime = Date.now();
    if (currentTime - lastUpdateTime > TAP_DELAY) {
      setUnclaimedPoints((prevPoints) => prevPoints + 1);
      setEnergy((prevEnergy) => prevEnergy + 1);
      setLastUpdateTime(currentTime);

      const newPlusTen = {
        id: currentTime,
        x: (Math.random() - 0.5) * RANDOM_POSITION_RANGE,
        y: (Math.random() - 0.5) * RANDOM_POSITION_RANGE,
        rotation: Math.random() * 360,
      };
      setPlusTens((current) => [...current, newPlusTen]);
      setTimeout(() => {
        setPlusTens((current) => current.filter((pt) => pt.id !== newPlusTen.id));
      }, PLUS_TEN_DURATION);
    }
  };

  // ⚡ Handle boost event
  const handleBoost = () => {
    console.log('Boost activated');
    setBoostTime((prevTime) => prevTime + BOOST_INCREMENT);
    setDailyIncomeLimit((prevIncome) => prevIncome * 1.5);
  };

  // 📦 Claim points
  const claimPoints = () => {
    console.log('Package opened');
    setBalance((prevScore) => prevScore + unclaimedPoints);
    setEnergy(0);
    setDailyIncomeLimit((prevIncome) => Math.max(0, prevIncome - unclaimedPoints));
    setUnclaimedPoints(0);
  };

  // 📧 Handle invite event
  const handleInvite = async () => {
    const shareUrl = 'https://t.me/ZenFoxes_bot';
    const shareText = 'Join me in ZenFoxes! Tap to earn coins, boost your score, and climb the leaderboard. Prepare for season one!';
    try {
      await utils.shareURL(shareUrl, shareText);
    } catch (error) {
      console.error('Error sharing URL:', error);
    }
  };

  // ⏲️ Update boost and claim times
  useEffect(() => {
    const timer = setInterval(() => {
      setBoostTime((prevTime) => Math.max(0, prevTime - 1));
      setClaimTime((prevTime) => Math.max(0, prevTime - 1));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // ⏳ Format time for display
  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const formattedClaimTime = formatTime(claimTime);

  return (
    <div className="overflow-hidden">
      {/* 🌌 Background overlay */}
      <div className="absolute top-0 left-0 w-full h-full -z-10 overflow-hidden pointer-events-none user-select-none after:content-[''] after:absolute after:inset-0" id="bg-overlay">
        <div className="shiny-overlay" />
        {/* 🌌 Background scene */}
        <SplineScene />
      </div>
      <main className="flex-1 p-4 pl-0 pr-0 pt-0 overflow-hidden w-full">
        {/* 💰 Daily income and balance card */}
        <Card className="bg-transparent border-transparent mb-4">
          <CardContent className="p-4 pb-0">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-gray-400">Daily Income</p>
                <p className="text-xl font-bold">
                  <span className="text-purple-400">⚡</span> {formatNumber(dailyIncome)}
                </p>
              </div>
              <div className="text-right">
                <p className="text-gray-400">Balance</p>
                <motion.p
                  key={score}
                  initial={{ opacity: 0.9 }}
                  animate={{ opacity: 1 }}
                  className="text-xl font-bold text-yellow-400"
                >
                  {formatNumber(score)} <span className="text-xs">$</span>
                </motion.p>
              </div>
            </div>
          </CardContent>
        </Card>
        {/* 🔋 Energy display */}
        <div className="flex flex-col items-center justify-center w-full mb-12">
          <div className="flex gap-8">
            <div className="w-12 h-12 bg-yellow-400 rounded-full flex items-center justify-center">
              <span className="text-3xl font-bold text-yellow-800">$</span>
            </div>
            <div
              className={`relative ${
                energy < 10
                  ? 'w-16'
                  : energy < 100
                  ? 'w-16'
                  : energy < 1000
                  ? 'w-24'
                  : energy < 10000
                  ? 'w-36'
                  : energy < 100000
                  ? 'w-42'
                  : energy < 1000000
                  ? 'w-56'
                  : 'w-64'
              } h-12 flex items-center`}
            >
              <AnimatePresence mode="sync">
                <motion.div
                  key={energy}
                  initial={{ opacity: 0, y: 2 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  className="left-0 text-5xl font-bold text-yellow-500"
                >
                  {energy > 9999999
                    ? formatNumber(Math.floor(energy))
                    : energy > 9999999
                    ? energy.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    : Math.floor(energy).toLocaleString()}
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        </div>
        {/* 🦊 Tap to earn points */}
        <div className="flex justify-center">
          <motion.div className="relative w-64 h-100" onClick={handleTap} role="button" aria-label="Tap to earn points">
            <img src="/character-2.png?v=213" alt="ZenFox" className="w-full h-full object-contain" />
            <div className="absolute inset-0" />
            <AnimatePresence>
              {plusTens.map((pt) => (
                <motion.div
                  key={pt.id}
                  className="absolute top-1/2 left-1/2 text-green-500 font-semibold text-3xl -z-10"
                  initial={{ opacity: 0, scale: 0.5, x: '-50%', y: '-50%', rotate: 0 }}
                  animate={{
                    opacity: [0, 1, 1, 0],
                    scale: [0.5, 2, 4, 18.5],
                    x: `calc(-50% + ${pt.x}px)`,
                    y: `calc(-50% + ${pt.y}px)`,
                    rotate: [0, pt.rotation, pt.rotation + 45],
                  }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{ duration: 1.5, times: [0, 0.2, 0.8, 1] }}
                  style={{
                    userSelect: 'none',
                    pointerEvents: 'none',
                  }}
                >
                  {crypto.getRandomValues(new Uint32Array(1))[0] % 2 === 0 ? <img src="/coin.webp" alt="Coin" className="w-6 h-6" /> : '+1'}
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
        {/* 📦 Boost and claim card */}
        <Card className="w-full mx-auto bg-transparent relative overflow-hidden p-4 rounded-xl -mt-12">
          <div className="absolute top-2 left-2 text-white px-2 py-1 rounded-lg z-10 flex items-center space-x-1">
            <Zap className="w-4 h-4 text-yellow-400 mr-1" />
            <span className="text-xs font-semibold">Boost {formatTime(boostTime)}</span>
          </div>
          <div className="mt-8 flex space-x-2 items-center">
            <div className="bg-blue-500 rounded-full h-16 flex-grow flex items-center justify-between px-4">
              <div className="flex items-center">
                <div className="bg-blue-600 rounded-full p-2">
                  <span className="text-sm font-bold text-white">{formattedClaimTime}</span>
                </div>
              </div>
              <div className="text-xl font-bold flex-grow flex items-center justify-center !cursor-pointer" onClick={handleTap} role="button" aria-label="Tap to earn points">
                <span className="text-white">TAP</span>
              </div>
              <div className="invisible">
                <div className="bg-blue-600 rounded-full p-2">
                  <span className="text-sm font-bold text-white">{formattedClaimTime}</span>
                </div>
              </div>
            </div>
            <Button className="bg-green-500 text-white p-3 rounded-full h-16 w-16 hover:bg-green-600" onClick={claimPoints}>
              <PackagePlus className="h-6 w-6" />
            </Button>
          </div>
          <div className="mt-4 flex justify-between items-center">
            <div className="flex flex-col-reverse items-center">
              <div className="text-xs text-gray-300 mt-2">Boost</div>
              <Button className="bg-purple-500 text-white p-3 rounded-full h-12 w-12 hover:bg-purple-600 flex items-center justify-center" onClick={handleBoost}>
                <PlugZap className="h-6 w-6" />
              </Button>
            </div>
            <div className="flex flex-col-reverse items-center">
              <div className="text-xs text-gray-300 mt-2">Invite</div>
              <Button className="bg-orange-500 text-white p-3 rounded-full h-12 w-12 hover:bg-orange-600 flex items-center justify-center" onClick={handleInvite}>
                <Share className="h-6 w-6" />
              </Button>
            </div>
          </div>
        </Card>
        {/* Integrate MessageBoard component */}
        {/* <MessageBoard userName={userName} /> */}
      </main>
    </div>
  );
}

