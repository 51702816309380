import React, { createContext, useState, useContext, ReactNode } from 'react';
 
interface GlobalState {
	token: string | null;
	language: string;
}

interface GlobalContextType extends GlobalState {
	setToken: (token: string | null) => void;
	setLanguage: (language: string) => void;
	user: {
		telegramId: string;
		firstName: string;
		lastName?: string;
		username?: string;
		languageCode?: string;
		points?: number;
		referrals?: number;
		rank?: number;
	} | null;
	setUser: (user: {
		telegramId: string;
		firstName: string;
		lastName?: string;
		username?: string;
		languageCode?: string;
		points?: number;
		referrals?: number;
		rank?: number;
	} | null) => void;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

interface GlobalProviderProps {
	children: ReactNode;
	initialLanguage: string;
	setLanguage: (language: string) => void;
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({
	children,
	initialLanguage,
	setLanguage: setAppLanguage,
}) => {
	const [token, setToken] = useState<string | null>(null);
	const [language, setLanguageState] = useState<string>(initialLanguage);
	const [user, setUser] = useState<GlobalContextType['user']>(null); 

	const setLanguage = (newLanguage: string) => {
		setLanguageState(newLanguage);
		setAppLanguage(newLanguage);
	};
	
	// useEffect(() => {
	// 	const init = async () => {
	// 		try {
	// 			const isLocalhost = true; 

	// 			const response = await convexInitMiniApp({ init_data_raw: initDataRaw || '', is_localhost:isLocalhost });
	// 			console.log('Convex initMiniApp response:', response);
	// 			if (response) {
	// 				setToken(response.token);
	// 				setUser(response.user);
	// 				// You might want to set other state variables based on the response
	// 			  }
	// 			// You can compare the results or use them as needed
	// 		} catch (error) {
	// 			console.error('Error initializing app:', error);
	// 		}
	// 	};

	// 	if (initDataRaw) {
	// 		init();
	// 	  }
	// }, []);
	
	return (
		<GlobalContext.Provider value={{ token, setToken, language, setLanguage, user, setUser }}>
			{children}
		</GlobalContext.Provider>
	);
};

export const useGlobalContext = () => {
	const context = useContext(GlobalContext);
	if (context === undefined) {
		throw new Error('useGlobalContext must be used within a GlobalProvider');
	}
	return context;
};
