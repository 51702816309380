import React, { useMemo, useEffect } from 'react';
import { useLaunchParams, useCloudStorage } from '@telegram-apps/sdk-react';
import { useErrorBoundary } from 'react-error-boundary';
import Home from '@/layouts/Home/Home';
import Onboarding from '@/layouts/Onboarding/Onboarding';
import { cacheWithCloudStorage } from '@/utils/cacheWithCloudStorage';
import { useLanguage } from '@/utils/LanguageContext';
import { useGlobalContext } from '@/context/GlobalContext';
import Loading from '@/utils/Loading';
import { useLoading } from '@/context/LoadingContext';
import { useInitMiniApp } from '@/services/convexApi';


const ONBOARDING_STATUS_KEY = 'hasCompletedOnboarding';
const ERROR_MESSAGES = {
	INIT_DATA_RAW_UNAVAILABLE: 'error.initDataRawUnavailable',
	TOKEN_MISSING: 'error.tokenMissing',
	UNKNOWN: 'error.unknown',
	RATE_LIMIT_EXCEEDED: 'error.rateLimitExceeded',
} as const;

type ErrorMessageKey = keyof typeof ERROR_MESSAGES;

const InitializerPage: React.FC = () => {
	const { isLoading: globalLoading } = useLoading();
	const { initDataRaw } = useLaunchParams();
	const cloudStorage = useCloudStorage();
	const { token, setToken, setLanguage, setUser } = useGlobalContext();
	const { t } = useLanguage();
	const cache = useMemo(() => cacheWithCloudStorage(cloudStorage), [cloudStorage]);
	const { showBoundary } = useErrorBoundary();

	const { execute: initMiniApp, isLoading: isInitLoading, error: initError } = useInitMiniApp();

	 
	// Onboarding Status: Assuming it's managed via cloud storage
	const [isOnboardingComplete, setIsOnboardingComplete] = React.useState<boolean | undefined>(undefined);
	const [isStatusLoading, setIsStatusLoading] = React.useState<boolean>(false);
	const [statusError, setStatusError] = React.useState<Error | null>(null);


	useEffect(() => {
		console.log('Initialized');
		return () => {
			console.log('Uninitialized');
		};
	}, []);

	// Mutation for setting onboarding status
	const setOnboardingComplete = async (completed: boolean) => {
		try {
		// console.log('Setting onboarding status:', completed);
		await cache.set(ONBOARDING_STATUS_KEY, completed);
		// Refetch onboarding status after setting
		const status = await cache.get<boolean>(ONBOARDING_STATUS_KEY);
		setIsOnboardingComplete(status ?? false);
		} catch (error: any) {
		console.error('Error setting onboarding status:', error);
		setStatusError(error);
		throw error;
		}
	};

	// Fetch Onboarding Status
	useEffect(() => {
		const fetchOnboardingStatus = async () => {
		setIsStatusLoading(true);
		setStatusError(null);
		try {
			// console.log('Fetching onboarding status');
			const status = await cache.get<boolean>(ONBOARDING_STATUS_KEY);
			setIsOnboardingComplete(status ?? false);
		} catch (error: any) {
			console.error('Error fetching onboarding status:', error);
			setStatusError(error);
		} finally {
			setIsStatusLoading(false);
		}
		};

		if (initDataRaw) {
		fetchOnboardingStatus();
		}
	}, [initDataRaw, cache]);
	
	  // Initialize Mini App
	  useEffect(() => {
		const initialize = async () => {
		  try {
			const isLocalhost = true;
			const response = await initMiniApp({
			  init_data_raw: initDataRaw || "",
			  is_localhost: isLocalhost,
			});
			// console.log("Convex initMiniApp response:", response);
			if (response) {
			  setToken(response.token);
			  setUser(response.user);
			  // Set other state variables as needed
			}
		  } catch (err) {
			// Error is already handled in the hook
			console.error('Error initializing app:', err);
		  }
		};
	
		if (initDataRaw) {
		  initialize();
		}
	  }, [initDataRaw]);
	
	  // Set Language
	  useEffect(() => {
		if (token) {
			// console.log('Setting language based on token');
			const newLanguageCode = 'en'; 
			setLanguage(newLanguageCode);
		}
	  }, [token, setLanguage]);
	
	  // Handle Errors
	  useEffect(() => {
		if (initError || statusError) {
		  const error = initError || statusError;
		  let errorMessage: ErrorMessageKey = 'UNKNOWN';
		  if (error instanceof Error) {
			console.error('Error occurred:', error.message);
			if (error.message === 'Rate limit exceeded. Please try again later.') {
			  errorMessage = 'RATE_LIMIT_EXCEEDED';
			} else if (error.message === 'init_data_raw unavailable') {
			  errorMessage = 'INIT_DATA_RAW_UNAVAILABLE';
			} else if (error.message === 'Token missing') {
			  errorMessage = 'TOKEN_MISSING';
			} else {
			  errorMessage = 'UNKNOWN';
			}
		  }
		  showBoundary(new Error(t(ERROR_MESSAGES[errorMessage])));
		}
	  }, [initError, statusError, showBoundary, t]);
	
	  // Render Loading States
	  if (globalLoading) {
		// console.log('App is still loading');
		return null;
	  }
	
	  if (isInitLoading || isStatusLoading) {
		// console.log('Init or status is loading');
		return <Loading />;
	  }
	
	  // Render Error Boundary
	  if (initError || statusError) {
		// console.log('Init or status error occurred');
		return null; // Boundary will handle the error display
	  }
	
	  // Render Loading if Data is Incomplete
	  if (token === null || isOnboardingComplete === undefined) {
		// console.log('Token or onboarding status is not available');
		return <Loading />;
	  }
	
	  console.log('Rendering main content');
	  
	return (
		<>
			{isOnboardingComplete ? (
				<Home />
			) : (
				<Onboarding onComplete={() => setOnboardingComplete(true)} />
			)}
		</>
	);
};

export default InitializerPage;
